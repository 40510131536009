import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { Modal, Button } from "react-bootstrap";

const FetchDetails = (data) => {
  const Code = useSelector((state) => state.ConfirmationCode.confirmationCode);

  useEffect(() => {
    if (Code) {
      console.log("Code", Code);
      handleShowTransitStatus();
      setActiveTab("TransitStatus");
    }
  }, [Code]);

  const finalData = data.data?.searchResult;

  //(finalData);

  const confirmationCode = data.data?.confirmationCode;

  const customer = finalData?.customer;

  const TravelPlan = finalData?.travelPlan;
  const Traveller = finalData?.traveller;
  const Matches = finalData?.match;
  let travellers;

  if (Traveller && Traveller.length > 0) {
    travellers = Traveller[0].travel_plan_id;
  }

  const filteredTravellers = Traveller?.filter(
    (traveller) => traveller.travel_plan_id === travellers
  );

  //(filteredTravellers);

  const payment = finalData?.payment;

  const notification = finalData?.notification;

  const feedback = finalData?.feedback;

  const transitStatus = finalData?.transitStatus;

  const [showModal, setShowModal] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0); // State to track refund amount
  const [currency, setCurrency] = useState(""); // State to track currency
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [feedbackSubject, setFeedbackSubject] = useState([]); // State to track feedback subject
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [totalRefunded, setTotalRefunded] = useState(0); // State to track feedback message

  const [selectedFeedback, setSelectedFeedback] = useState("");
  const [amount, setAmount] = useState(0);

  const handleFeedbackChange = (event) => {
    setSelectedFeedback(event.target.value);
  };

  const handleRefund = (
    amount,
    currency,
    payment_intent_id,
    refunded_amount
  ) => {
    setTotalRefunded(refunded_amount);
    setAmount(amount);
    setCurrency(currency);
    setPaymentIntentId(payment_intent_id);

    setShowModal(true);
  };

  const handleCloseModal = () => {
    setTotalRefunded(0);
    setAmount(0);
    setRefundAmount(0);
    setCurrency("");
    setPaymentIntentId("");
    setShowModal(false);
  };

  const submitRefund = () => {
    //(refundAmount);
    //(amount);
    if (refundAmount <= 0) {
      alert("Please enter a valid amount");
      return;
    }

    if (refundAmount > amount) {
      alert("Refund amount cannot be greater than the total amount");
      return;
    }
    if (refundAmount > amount - totalRefunded) {
      alert("Refund amount cannot be greater than the total amount");
      return;
    }

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    //(totalRefunded);

    const body = {
      paymentIntentId: paymentIntentId,
      refundAmount: refundAmount,
      amount: amount,
      totalRefunded: totalRefunded,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}refund-payment`, body, {
        headers,
      })
      .then((response) => {
        alert("Refund Successful");
        data.data.check(true);
      })
      .catch((error) => {
        // Handle the error here
        console.error(error);
      });

    handleCloseModal();
  };
  const appNotifications = notification?.filter(
    (notif) => notif.message_type === "APP"
  );
  const smsNotifications = notification?.filter(
    (notif) => notif.message_type === "SMS"
  );
  const emailNotifications = notification?.filter(
    (notif) => notif.message_type === "EMAIL"
  );
  const webNotifications = notification?.filter(
    (notif) => notif.message_type === "WEB"
  );
  const [selectedMessageType, setSelectedMessageType] = useState(
    appNotifications?.length > 0 ? "APP" : "SMS"
  );

  const fetchFeedbackSubject = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}feedback`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      //(response.data.data);
      setFeedbackSubject(response.data.data);
    } catch (error) {
      alert(error?.response?.data?.data || "Something went wrong!");
    }
  };

  useEffect(() => {
    fetchFeedbackSubject();
  }, []);

  const handleFeedbackSubmit = async () => {
    //(selectedFeedback);

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    const body = {
      feedback_subject_id: selectedFeedback,
      message: feedbackMessage,
      confirmation_code: confirmationCode,
    };

    //(body);

    axios
      .post(`${process.env.REACT_APP_API_URL}addfeedback`, body, {
        headers,
      })
      .then((response) => {
        alert("Feedback Submitted");
        setFeedbackMessage("");
        setSelectedFeedback("");

        data.data.check(true);
      })
      .catch((error) => {
        // Handle the error here
        console.error(error);
      });
  };
  const [showTravelPlan, setShowTravelPlan] = useState(true);
  const [showTraveller, setShowTraveller] = useState(false);
  const [showMatch, setShowMatch] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showTransitStatus, setShowTransitStatus] = useState(false);
  const [showCustomer, setShowCustomer] = useState(false);
  const [activeTab, setActiveTab] = useState("TravelPlan");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    switch (tabName) {
      case "TravelPlan":
        handleShowTravelPlan();
        break;
      case "Traveller":
        handleShowTraveller();
        break;
      case "Matches":
        handleShowMatch();
        break;
      case "Payment":
        handleShowPayment();
        break;
      case "Notification":
        handleShowNotification();
        break;
      case "Feedback":
        handleShowFeedback();
        break;
      case "TransitStatus":
        handleShowTransitStatus();
        break;
      case "Customer":
        handleShowCustomer();
        break;
      default:
        break;
    }
  };

  const handleShowTravelPlan = () => {
    setShowCustomer(false);
    setShowTravelPlan(true);
    setShowTraveller(false);
    setShowMatch(false);
    setShowFeedback(false);
    setShowNotification(false);
    setShowPayment(false);
    setShowTransitStatus(false);
  };

  const handleShowTraveller = () => {
    setShowTraveller(true);
    setShowTravelPlan(false);
    setShowMatch(false);
    setShowFeedback(false);
    setShowNotification(false);
    setShowPayment(false);
    setShowTransitStatus(false);
    setShowCustomer(false);
  };
  const handleShowMatch = () => {
    setShowMatch(true);
    setShowTravelPlan(false);
    setShowTraveller(false);
    setShowFeedback(false);
    setShowNotification(false);
    setShowPayment(false);
    setShowTransitStatus(false);
    setShowCustomer(false);
  };

  const handleShowPayment = () => {
    setShowPayment(true);
    setShowTravelPlan(false);
    setShowTraveller(false);
    setShowMatch(false);
    setShowFeedback(false);
    setShowNotification(false);
    setShowTransitStatus(false);
    setShowCustomer(false);
  };

  const handleShowNotification = () => {
    setShowNotification(true);
    setShowTravelPlan(false);
    setShowTraveller(false);
    setShowMatch(false);
    setShowFeedback(false);
    setShowPayment(false);
    setShowTransitStatus(false);
    setShowCustomer(false);
  };

  const handleShowFeedback = () => {
    setShowFeedback(true);
    setShowTravelPlan(false);
    setShowTraveller(false);
    setShowMatch(false);
    setShowNotification(false);
    setShowPayment(false);
    setShowTransitStatus(false);
    setShowCustomer(false);
  };

  const handleShowTransitStatus = () => {
    setShowTransitStatus(true);
    setShowTravelPlan(false);
    setShowTraveller(false);
    setShowMatch(false);
    setShowNotification(false);
    setShowFeedback(false);
    setShowPayment(false);
    setShowCustomer(false);
  };

  const handleShowCustomer = () => {
    setShowCustomer(true);
    setShowTravelPlan(false);
    setShowTraveller(false);
    setShowMatch(false);
    setShowNotification(false);
    setShowFeedback(false);
    setShowPayment(false);
    setShowTransitStatus(false);
  };
  return (
    <>
      {finalData && (
        <div className="container mt-4 ml-4">
          <ul className="nav nav-tabs">
            <li className="nav-item" style={{ cursor: "pointer" }}>
              <a
                className={`nav-link ${
                  activeTab === "TravelPlan" ? "active" : ""
                }`}
                onClick={() => handleTabClick("TravelPlan")}
              >
                Travel Plan
              </a>
            </li>
            <li className="nav-item" style={{ cursor: "pointer" }}>
              <a
                className={`nav-link ${
                  activeTab === "Traveller" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Traveller")}
              >
                Traveller
              </a>
            </li>
            {/* Add similar blocks for other tabs */}
            <li className="nav-item" style={{ cursor: "pointer" }}>
              <a
                className={`nav-link ${
                  activeTab === "Matches" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Matches")}
              >
                Matches
              </a>
            </li>
            <li className="nav-item" style={{ cursor: "pointer" }}>
              <a
                className={`nav-link ${
                  activeTab === "Payment" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Payment")}
              >
                Payment
              </a>
            </li>
            <li className="nav-item" style={{ cursor: "pointer" }}>
              <a
                className={`nav-link ${
                  activeTab === "Notification" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Notification")}
              >
                Notification
              </a>
            </li>
            <li className="nav-item" style={{ cursor: "pointer" }}>
              <a
                className={`nav-link ${
                  activeTab === "Feedback" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Feedback")}
              >
                Feedback
              </a>
            </li>
            <li className="nav-item" style={{ cursor: "pointer" }}>
              <a
                className={`nav-link ${
                  activeTab === "TransitStatus" ? "active" : ""
                }`}
                onClick={() => handleTabClick("TransitStatus")}
              >
                Transit Status
              </a>
            </li>
            <li className="nav-item" style={{ cursor: "pointer" }}>
              <a
                className={`nav-link ${
                  activeTab === "Customer" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Customer")}
              >
                Customer
              </a>
            </li>
          </ul>
          <div className="container mt-3">
            <div className="card bg-light">
              <div className="card-body">
                {showCustomer && (
                  <div className="container mt-3">
                    <div className="card bg-light">
                      <div className="card-body">
                        <h4 className="card-text  mt-4 mb-2 ">
                          Customer Information
                        </h4>
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <th>Name</th>
                              <td>
                                {customer[0]?.first_name}{" "}
                                {customer[0]?.last_name}
                              </td>
                            </tr>
                            <tr>
                              <th>Phone Number</th>
                              <td>
                                {customer[0]?.country_phone_code}{" "}
                                {customer[0]?.phone_number}
                              </td>
                            </tr>
                            <tr>
                              <th>Email Address</th>
                              <td>{customer[0]?.email_address}</td>
                            </tr>
                            <tr>
                              <th>Corporate Email Address</th>
                              <td>{customer[0]?.corporate_email_address}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {showTravelPlan && (
                  <div className="container mt-3">
                    <div className="card bg-light">
                      <div className="card-body overflow-auto">
                        <h4 className="card-text mt-4 mb-2">Travel Plan</h4>
                        {Array.isArray(TravelPlan) && TravelPlan.length > 0 ? (
                          <table className="table table-striped overflow-auto">
                            <thead>
                              <tr>
                                {TravelPlan[0]?.date_of_travel && (
                                  <th>Date of Boarding</th>
                                )}
                                {TravelPlan[0]?.start_date_of_travel && (
                                  <>
                                    <th>Start Date of Travel</th>
                                    <th>End Date of Travel</th>
                                  </>
                                )}
                                <th>Airline Name</th>
                                <th>Flight Code</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Ticket Booked</th>
                                <th>Travel Plan Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {TravelPlan.map((travel, index) => (
                                <tr key={index}>
                                  {travel.date_of_travel && (
                                    <td>{travel.date_of_travel}</td>
                                  )}
                                  {travel.start_date_of_travel && (
                                    <>
                                      <td>{travel.start_date_of_travel}</td>
                                      <td>{travel.end_date_of_travel}</td>
                                    </>
                                  )}
                                  <td>{travel.airline_name}</td>
                                  <td>{travel.flight_code}</td>
                                  <td>
                                    ({travel.from_airport_iata_code}){" "}
                                    {travel.from_airport_name},{" "}
                                    {travel.fromCity}
                                  </td>
                                  <td>
                                    ({travel.to_airport_iata_code}){" "}
                                    {travel.to_airport_name}, {travel.toCity}
                                  </td>
                                  <td>{travel.ticket_booked ? "Yes" : "No"}</td>
                                  <td>
                                    {travel.travel_plan_status === "MATCH_FOUND"
                                      ? "Match Found"
                                      : "Created"}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <p>No travel plans available.</p>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {showTraveller && (
                  <div className="container mt-3">
                    <div className="card bg-light">
                      <div className="card-body overflow-auto">
                        <h4 className="card-text  mt-4 mb-2 ">
                          Traveler Information
                        </h4>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Age</th>
                              <th>Gender</th>
                              <th>Primary Language</th>
                              <th>Secondary Language</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredTravellers?.map((data, index) => (
                              <tr key={index}>
                                <td>
                                  {data.first_name} {data.last_name}
                                </td>
                                <td>{data.age_range_name}</td>
                                <td>
                                  {data.gender === "M" ? "Male" : "Female"}
                                </td>
                                <td>{data.primaryLanguage}</td>
                                <td>{data.secondaryLanguage}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {showMatch && (
                  <div className="container mt-3">
                    <div className="card bg-light">
                      <div className="card-body overflow-auto">
                        <h4 className="card-text  mt-4 mb-2">
                          Matched Flights
                        </h4>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th> Name</th>
                              <th>Match Type</th>
                              <th>Phone Number</th>

                              <th>Match Status</th>

                              <th>Revealed</th>
                              <th>Flight Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Matches?.map((flight, index) => (
                              <tr key={index}>
                                <td>
                                  {flight.customer_first_name}{" "}
                                  {flight.customer_last_name}
                                </td>
                                <td>{flight.match_type}</td>
                                <td>
                                  {flight.customer_country_phone_code}{" "}
                                  {flight.customer_phone_number}
                                </td>

                                <td>{flight.match_status}</td>

                                <td>{flight.revealed ? "Yes" : "No"}</td>

                                <td>
                                  From: {flight.fromCity},{" "}
                                  {flight.from_airport_name}
                                  <br />
                                  To: {flight.toCity}, {flight.to_airport_name}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {showPayment && (
                  <div className="container mt-3">
                    <div className="card bg-light">
                      <div className="card-body overflow-auto">
                        <h4 className="card-text  mt-4 mb-2">
                          Payment Details
                        </h4>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Amount(USD)</th>
                              <th>Status</th>
                              <th>Refunded Amount</th>
                              <th>Source Type</th>
                              <th>Note</th>
                              <th>Payment Initiation DateTime</th>
                              <th>Receipt Number</th>
                              <th>Refund DateTime</th>

                              <th>Payment Completion DateTime</th>
                            </tr>
                          </thead>
                          <tbody>
                            {payment.length >= 1 &&
                              payment?.map((data, index) => (
                                <tr key={index}>
                                  <td>{data.amount / 100}</td>
                                  <td>{data.status}</td>
                                  <td>{data.refunded_amount}</td>
                                  <td>{data.source_type}</td>
                                  <td>{data.note}</td>
                                  <td>
                                    {new Date(
                                      data.payment_initiation_date_time
                                    ).toLocaleString("en-US", {
                                      dateStyle: "medium",
                                      timeStyle: "medium",
                                    })}
                                  </td>

                                  <td>{data.receipt_number}</td>
                                  <td>
                                    {new Date(
                                      data.refund_date_time
                                    ).toLocaleString("en-US", {
                                      dateStyle: "medium",
                                      timeStyle: "medium",
                                    })}
                                  </td>

                                  <td>
                                    {new Date(
                                      data.payment_completion_date_time
                                    ).toLocaleString("en-US", {
                                      dateStyle: "medium",
                                      timeStyle: "medium",
                                    })}
                                  </td>
                                  {data.status !== "Full Refunded" && (
                                    <td>
                                      <button
                                        className="refund-button"
                                        onClick={() =>
                                          handleRefund(
                                            data.amount / 100,
                                            data.currency,
                                            data.payment_intent_id,
                                            data.refunded_amount
                                          )
                                        }
                                      >
                                        Refund
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {showNotification && (
                  <div>
                    <div className="container mt-3">
                      <div className="btn-group" role="group">
                        {appNotifications?.length > 0 && (
                          <button
                            className={`btn ${
                              selectedMessageType === "APP"
                                ? "btn-primary"
                                : "btn-secondary"
                            }`}
                            onClick={() => setSelectedMessageType("APP")}
                          >
                            App
                          </button>
                        )}
                        <button
                          className={`btn ${
                            selectedMessageType === "SMS"
                              ? "btn-primary"
                              : "btn-secondary"
                          }`}
                          onClick={() => setSelectedMessageType("SMS")}
                        >
                          SMS
                        </button>
                        <button
                          className={`btn ${
                            selectedMessageType === "EMAIL"
                              ? "btn-primary"
                              : "btn-secondary"
                          }`}
                          onClick={() => setSelectedMessageType("EMAIL")}
                        >
                          Email
                        </button>
                        <button
                          className={`btn ${
                            selectedMessageType === "WEB"
                              ? "btn-primary"
                              : "btn-secondary"
                          }`}
                          onClick={() => setSelectedMessageType("WEB")}
                        >
                          Web
                        </button>
                      </div>
                    </div>

                    {selectedMessageType === "APP" &&
                      appNotifications &&
                      appNotifications.length > 0 && (
                        <div className="container mt-3">
                          <div className="card bg-light">
                            <div className="card-body overflow-auto">
                              <h4 className="card-text mt-4 mb-2">
                                Notifications
                              </h4>

                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Message Type</th>
                                    <th>Message</th>
                                    <th>Sent Timestamp</th>
                                    <th>Confirmation Code</th>
                                    <th>Notification Category</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {appNotifications?.map((notif, index) => (
                                    <tr key={index}>
                                      <td>{notif.message_type}</td>
                                      <td>{notif.message}</td>
                                      <td>
                                        {new Date(
                                          notif.sent_timestamp
                                        ).toLocaleString("en-US", {
                                          dateStyle: "medium",
                                          timeStyle: "medium",
                                        })}
                                      </td>
                                      <td>{notif.confirmation_code}</td>
                                      <td>{notif.notification_category}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}

                    {selectedMessageType === "SMS" &&
                      smsNotifications &&
                      smsNotifications.length > 0 && (
                        // Section for SMS Notifications
                        <div className="container mt-3">
                          <div className="card bg-light">
                            <div className="card-body overflow-auto">
                              <h4 className="card-text mt-4 mb-2">
                                Notifications
                              </h4>
                              {/* Render table for SMS notifications */}
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Message Type</th>
                                    <th>Message</th>
                                    <th>Sent Timestamp</th>
                                    <th>Confirmation Code</th>
                                    <th>Notification Category</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {smsNotifications?.map((notif, index) => (
                                    <tr key={index}>
                                      <td>{notif.message_type}</td>
                                      <td>{notif.message}</td>
                                      <td>
                                        {new Date(
                                          notif.sent_timestamp
                                        ).toLocaleString("en-US", {
                                          dateStyle: "medium",
                                          timeStyle: "medium",
                                        })}
                                      </td>
                                      <td>{notif.confirmation_code}</td>
                                      <td>{notif.notification_category}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}

                    {selectedMessageType === "EMAIL" &&
                      emailNotifications &&
                      emailNotifications.length > 0 && (
                        // Section for EMAIL Notifications
                        <div className="container mt-3">
                          <div className="card bg-light">
                            <div className="card-body overflow-auto">
                              <h4 className="card-text mt-4 mb-2">
                                Notifications
                              </h4>
                              {/* Render table for EMAIL notifications */}
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Message Type</th>
                                    <th>Message</th>
                                    <th>Sent Timestamp</th>
                                    <th>Confirmation Code</th>
                                    <th>Notification Category</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {emailNotifications?.map((notif, index) => (
                                    <tr key={index}>
                                      <td>{notif.message_type}</td>
                                      <td>{notif.message}</td>
                                      <td>
                                        {new Date(
                                          notif.sent_timestamp
                                        ).toLocaleString("en-US", {
                                          dateStyle: "medium",
                                          timeStyle: "medium",
                                        })}
                                      </td>
                                      <td>{notif.confirmation_code}</td>
                                      <td>{notif.notification_category}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}

                    {selectedMessageType === "WEB" &&
                      webNotifications &&
                      webNotifications.length > 0 && (
                        // Section for WEB Notifications
                        <div className="container mt-3">
                          <div className="card bg-light">
                            <div className="card-body overflow-auto">
                              <h4 className="card-text mt-4 mb-2">
                                Notifications
                              </h4>
                              {/* Render table for WEB notifications */}
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Message Type</th>
                                    <th>Message</th>
                                    <th>Sent Timestamp</th>
                                    <th>Confirmation Code</th>
                                    <th>Notification Category</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {webNotifications?.map((notif, index) => (
                                    <tr key={index}>
                                      <td>{notif.message_type}</td>
                                      <td>{notif.message}</td>
                                      <td>
                                        {new Date(
                                          notif.sent_timestamp
                                        ).toLocaleString("en-US", {
                                          dateStyle: "medium",
                                          timeStyle: "medium",
                                        })}
                                      </td>
                                      <td>{notif.confirmation_code}</td>
                                      <td>{notif.notification_category}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                )}
                {showFeedback && (
                  <div className="container mt-3">
                    <div className="card bg-light">
                      <div className="card-body overflow-auto">
                        <h4 className="card-text mt-4 mb-2">Feedback</h4>
                        <table className="table table-striped">
                          {feedback?.length > 0 && (
                            <thead>
                              <tr>
                                <th>Feedback Type</th>
                                <th>Feedback</th>
                                <th>Phone</th>
                                <th>Email</th>

                                <th>Country</th>
                                <th>Feedback Timestamp</th>
                              </tr>
                            </thead>
                          )}
                          <tbody>
                            {feedback?.map((feed, index) => (
                              <tr key={index}>
                                <td>{feed.subject}</td>
                                <td>{feed.message}</td>

                                <td>{feed.phone_number}</td>
                                <td>{feed.email}</td>

                                <td>{feed.country_name}</td>

                                <td>
                                  {new Date(feed.created_at).toLocaleString(
                                    "en-US",
                                    {
                                      dateStyle: "medium",
                                      timeStyle: "medium",
                                    }
                                  )}
                                </td>
                              </tr>
                            ))}

                            <div className=" mt-4 mb-3">
                              <select
                                className="form-select"
                                id="feedbackSelect"
                                value={selectedFeedback}
                                onChange={handleFeedbackChange}
                              >
                                <option value="">Select Feedback</option>
                                {feedbackSubject?.map((feed, index) => (
                                  <option
                                    key={index}
                                    value={feed.feedback_subject_id}
                                  >
                                    {feed.subject}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="mb-3">
                              <label
                                htmlFor="feedbackMessage"
                                className="form-label"
                              >
                                Feedback Message
                              </label>
                              <textarea
                                className="form-control"
                                rows="3"
                                disabled={!selectedFeedback}
                                onChange={(e) =>
                                  setFeedbackMessage(e.target.value)
                                }
                                value={feedbackMessage}
                              ></textarea>
                            </div>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={!selectedFeedback}
                              onClick={handleFeedbackSubmit}
                            >
                              Submit Feedback
                            </button>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {showTransitStatus && (
                  <div className="container mt-3">
                    <div className="card bg-light">
                      <div className="card-body overflow-auto">
                        <h4 className="card-text mt-4 mb-2">Transit Status</h4>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Transit Step</th>
                              <th>Created Time</th>
                              <th>Updated Time</th>
                              <th>Order</th>
                              <th>Step Completed</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transitStatus?.map((transit, index) => (
                              <tr key={index}>
                                <td>{transit.transit_code}</td>

                                <td>
                                  {new Date(
                                    transit.created_time_stamp
                                  ).toLocaleString("en-US", {
                                    dateStyle: "medium",
                                    timeStyle: "medium",
                                  })}
                                </td>
                                <td>
                                  {new Date(
                                    transit.updated_time_stamp
                                  ).toLocaleString("en-US", {
                                    dateStyle: "medium",
                                    timeStyle: "medium",
                                  })}
                                </td>
                                <td>{transit.order}</td>
                                <td>{transit.step_completed ? "Yes" : "No"}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Refund Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-3">
                <label htmlFor="refundAmount" className="form-label">
                  Total Amount
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="refundAmount"
                  value={`${refundAmount}`}
                  onChange={(e) => setRefundAmount(e.target.value)}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button variant="primary" onClick={submitRefund}>
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default FetchDetails;
