import React from "react";
import State from "../components/State";
import Sidebar from "../components/Sidebar";
import { Nav } from "react-bootstrap";
import NavbarComponent from "../components/Navbar";

const StatePage = () => {
  return (
    <>
      <NavbarComponent />
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1">
          <State />
        </div>
      </div>
    </>
  );
};

export default StatePage;
