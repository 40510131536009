import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Pagination, Modal, Button } from "react-bootstrap"; // Import Modal and Button from react-bootstrap

const ServiceType = () => {
  const [airlineData, setAirlineData] = useState([]);
  const [airlineOptions, setAirlineOptions] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [areaOfService, setareaOfService] = useState("");
  const [airlineIataCode, setAirlineIataCode] = useState("");
  const [checkUpdate, setCheckUpdate] = useState(null);
  const [typeName, settypeName] = useState("");
  const [domestic, setDomestic] = useState(); // Set default value for domestic and international
  const [international, setInternational] = useState(); // Set default value for domestic and international
  const [countries, setCountries] = useState([]);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = airlineData?.slice(indexOfFirstRow, indexOfLastRow);
  const [active, setActive] = useState(false);
  //e.log(airlineData);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setareaOfService("");

    setActive(false);
    setCheckUpdate(null);
    settypeName("");

    setShowModal(false);
  };

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}service_type`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        //e.log(response.data);
        setAirlineData(response.data);
        //(response.data.data);
      })
      .catch((error) => {
        //(error);
        alert("something went wrong");
      });
    axios
      .get(`${process.env.REACT_APP_API_URL}country`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        alert("something went wrong");
      });
  }, [showModal]);

  const handleAddAirline = () => {
    if (!areaOfService || !typeName) {
      alert("Please fill all the fields");
      return;
    }

    const formData = {
      area_of_service: areaOfService,
      type: typeName,
      active: active ? 1 : 0,
    };

    if (checkUpdate !== null) {
      //e.log("update");
      axios
        .post(
          `${process.env.REACT_APP_API_URL}service_type/${checkUpdate}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setAirlineData([...airlineData, response.data]);

          setareaOfService("");
          settypeName("");
          setActive(false);
          setCheckUpdate(null);
          setShowModal(false);
        })
        .catch((error) => {
          //(error);
          console.log(error);
          alert("Service Type already exists");
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}service_type`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setAirlineData([...airlineData, response.data]);

          setareaOfService("");
          settypeName("");

          setActive(false);
          setCheckUpdate(null);

          setShowModal(false);
          //e.log(response);
          console.log(response);
        })
        .catch((error) => {
          //(error);
          console.log(error);
          //e.log(error);
          console.log(error);
          alert("Service Type already exists");
        });
    }
  };
  const handleUpdateAirline = (e) => {
    handleShowModal();
    setCheckUpdate(e);
    //e.log(e);
    axios
      .get(`${process.env.REACT_APP_API_URL}service_type/${e}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        //(response.data.data);
        //e.log(response.data);
        //(response.data[0]);
        setareaOfService(response.data[0].area_of_service_id);

        setActive(response.data[0].active);
        settypeName(response.data[0].type);
      })
      .catch((error) => {
        //(error);
        console.log(error);
        alert("something went wrong");
      });
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}area_of_service`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setAirlineOptions(response.data);
      })
      .catch((error) => {
        //(error);
        console.log(error);
        alert("something went wrong");
      });
  }, [showModal]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Service Type</h1>
        <button
          className="btn btn-primary "
          style={{ marginRight: "1rem" }}
          onClick={handleShowModal}
        >
          ADD
        </button>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>SNo.</th>
            <th>Area of Service Code</th>
            <th>Area Of Service Description</th>
            <th>Type</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {currentRows?.map((airline, index) => (
            <tr key={airline?.service_type_id}>
              <td>{indexOfFirstRow + index + 1}</td>
              <td
                onClick={() => handleUpdateAirline(airline?.service_type_id)}
                style={{
                  cursor: "pointer",
                  color: "blue",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {airline?.area_of_service_code}
              </td>
              <td>{airline?.description}</td>
              <td>{airline?.type}</td>
              <td>{airline?.active ? "Yes" : "No"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
        <Pagination>
          {Array(Math?.ceil(airlineData?.length / rowsPerPage))
            ?.fill()
            ?.map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
        </Pagination>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Service Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="addAirlineForm">
            <div className="form-group">
              <label htmlFor="areaOfService">Area Of Services</label>
              <select
                className="form-control"
                onChange={(e) => setareaOfService(e.target.value)}
                name="areaOfService"
                value={areaOfService}
                required
              >
                <option value="">Select an area of service</option>

                {airlineOptions?.map((airline) => (
                  <option
                    key={airline.area_of_service_id}
                    value={airline.area_of_service_id}
                  >
                    {airline.area_of_service_code}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="Type">Type</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => settypeName(e.target.value)}
                name="Type"
                placeholder="Enter Type"
                value={typeName}
                required
              />
            </div>

            <div className="form-group">
              <div style={{ display: "flex" }}>
                <label
                  htmlFor="active"
                  style={{
                    marginRight: "1rem",
                  }}
                >
                  Active
                </label>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => setActive(e.target.checked)}
                    name="active"
                    checked={active}
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddAirline}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ServiceType;
