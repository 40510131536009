import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./index.css";
import App from "./App";
import LoginPage from "./pages/LoginPage";
import UserDetails from "./pages/UserDetails";
import Sidebar from "./components/Sidebar";
import Error from "./components/Error";
import Login from "./components/Login";
import Travel from "./pages/Travel";
import CountryChart from "./charts/CountryChart";
import AirlinePage from "./pages/AirlinePage";
import Flight from "./pages/FlightPage";
import Country from "./components/Country";
import CountryPage from "./pages/CountryPage";
import StatePage from "./pages/StatePage";
import Language from "./components/Language";
import LanguagePage from "./pages/LanguagePage";
import AgePage from "./pages/AgePage";
import FeedbackPage from "../src/pages/FeedbackPage";
import PhonePage from "./pages/PhonePage";
import ServicesTypePage from "./pages/ServicesTypePage";
import AreaOfServicePage from "./pages/AreaOfServicePage";
import ServicePage from "./pages/ServicePage";
import TransitStepsPage from "./pages/TransitStepsPage";
import AirportSourceTransitStepPage from "./pages/AirportSourceTransitStepPage";
import AirportDestinationTransitStepPage from "./pages/AirportDestinationTransitStepPage";
import TransitPricingPage from "./pages/TransitPricingPage";
import ProgressPage from "./pages/ProgressPage";
import { Provider } from "react-redux";
import appStore from "./utils/appStore";
import UsersPage from "./pages/UsersPage";
import EnableFlightPage from "./pages/EnableFilghtPage";

const Root = () => {
  const token = !!localStorage.getItem("token");

  return (
    <Provider store={appStore}>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={token ? <Navigate to="/" /> : <LoginPage />}
          />

          {token ? (
            <>
              <Route path="/" element={<Travel />} />
              <Route path="/chart" element={<CountryChart />} />
              <Route path="/airline" element={<AirlinePage />} />
              <Route path="/flight" element={<Flight />} />
              <Route path="/country" element={<CountryPage />} />
              <Route path="/state" element={<StatePage />} />
              <Route path="/language" element={<LanguagePage />} />
              <Route path="/age" element={<AgePage />} />
              <Route path="/feedback" element={<FeedbackPage />} />
              <Route path="/phone" element={<PhonePage />} />
              <Route path="/area-of-service" element={<AreaOfServicePage />} />
              <Route path="/service-type" element={<ServicesTypePage />} />
              <Route path="/service" element={<ServicePage />} />
              <Route path="/transit_step" element={<TransitStepsPage />} />
              <Route path="/user" element={<UsersPage />} />
              <Route
                path="/airport_source_transit_step"
                element={<AirportSourceTransitStepPage />}
              />
              <Route
                path="/airport_destination_transit_step"
                element={<AirportDestinationTransitStepPage />}
              />
              <Route path="/transit-pricing" element={<TransitPricingPage />} />
              <Route path="/progress" element={<ProgressPage />} />
              <Route path="/flight-enable" element={<EnableFlightPage />} />
            </>
          ) : (
            // Redirect to login if no token
            <Route path="*" element={<Navigate to="/login" />} />
          )}

          {/* 404 Route */}
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </Provider>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<Root />);
