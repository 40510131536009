import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Pagination, Modal, Button } from "react-bootstrap";

const State = () => {
  const [flightInfoData, setFlightInfoData] = useState([]);
  const [fromAirport, setFromAirports] = useState("");
  const [toAirport, setToAirports] = useState("");
  const [airports, setAirports] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(15);
  const [countryCode, setCountryCode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [fromAirports, setFromAirport] = useState("");
  const [toAirports, setToAirport] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [checkUpdate, setCheckUpdate] = useState(null);
  const [airlineOptions, setAirlineOptions] = useState();
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countries, setCountries] = useState([]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = states?.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setCountryCode("");
    setCountryName("");
    setFromAirports("");
    setToAirports("");

    setCheckUpdate(null);

    setShowModal(false);
  };

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}flightinformation`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setFlightInfoData(response.data.data);
      })
      .catch((error) => {
        //.error(error);
      });
  }, [showModal]);

  const handleAddFlightInfo = () => {
    //.log(countryCode, countryName);
    if (!countryCode || !countryName) {
      alert("Please fill all the fields");
      return;
    }

    const formData = {
      country_code: countryCode,
      country_name: countryName,
      active: "1",
      priority: "99",
    };

    if (checkUpdate !== null) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}country/${checkUpdate}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setFlightInfoData([...flightInfoData, response.data.data]);
          setCountryCode("");
          setCountryName("");
          setFromAirports("");
          setToAirports("");

          setShowModal(false);
          setCheckUpdate(null);
        })
        .catch((error) => {
          //.error(error);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}createCountry`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setFlightInfoData([...flightInfoData, response.data.data]);
          setCountryCode("");
          setCountryName("");
          setFromAirport("");
          setToAirport("");
          setArrivalTime("");
          setCheckUpdate(null);
          setShowModal(false);
        })
        .catch((error) => {
          //.error(error);
        });
    }
  };

  const handleUpdateFlightInfo = (e) => {
    //.log(e);
    handleShowModal();
    setCheckUpdate(e);
    axios
      .get(`${process.env.REACT_APP_API_URL}country/${e}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        //.log(response.data);
        setCountryCode(response.data.data.country_code);
        setCountryName(response.data.data.country_name);
      })
      .catch((error) => {
        //.error(error);
      });
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(states?.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}allCountry`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setAirports(response.data.data);
      })
      .catch((error) => {});
  }, [showModal]);
  const fetchStatesByCountry = (countryId) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}state/${countryId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setStates(response.data.data);
      })
      .catch((error) => {});
  };

  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value;
    setSelectedCountry(selectedCountryId);

    if (selectedCountryId) {
      fetchStatesByCountry(selectedCountryId);
    } else {
      setStates([]);
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}allCountry`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {});
  }, []);
  return (
    <div>
      <div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h1 className="mb-4">States</h1>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h3 className="me-3">Select a Country:</h3>
              <select
                className="form-select"
                value={selectedCountry}
                onChange={handleCountryChange}
                style={{ width: "50%" }}
              >
                <option value="">Select a Country</option>
                {countries.map((country) => (
                  <option key={country.country_id} value={country.country_id}>
                    {country.country_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button
            className="btn btn-primary "
            style={{ marginRight: "1rem" }}
            onClick={handleShowModal}
          >
            ADD
          </button>
        </div>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>SNo.</th>
              <th>State Code</th>
              <th>State Name</th>
            </tr>
          </thead>
          <tbody>
            {currentRows?.map((flightInfo, index) => (
              <tr key={flightInfo?.flight_id}>
                <td>{indexOfFirstRow + index + 1}</td>
                <td
                  onClick={() => handleUpdateFlightInfo(flightInfo?.country_id)}
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  {flightInfo?.state_code}
                </td>
                <td>{flightInfo?.state_name}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="d-flex justify-content-center">
          <Pagination>
            {pageNumbers.map((number, index) => {
              if (
                index === 0 ||
                index === pageNumbers?.length - 1 ||
                Math.abs(currentPage - number) <= 1
              ) {
                return (
                  <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </Pagination.Item>
                );
              } else if (index === 1 || index === pageNumbers?.length - 2) {
                return (
                  <Pagination.Ellipsis
                    key={number}
                    onClick={() => paginate(number)}
                  />
                );
              } else {
                return null;
              }
            })}
          </Pagination>
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Flight Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form id="addFlightInfoForm">
              <div className="form-group">
                <label htmlFor="countryCode">Country Code</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setCountryCode(e.target.value)}
                  name="countryCode"
                  placeholder="Enter Country Code"
                  value={countryCode}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="countryCode">Country Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setCountryName(e.target.value)}
                  name="countryCode"
                  placeholder="Enter Country Name"
                  value={countryName}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="active">Active</label>
                <input
                  type="text"
                  className="form-control"
                  name="active"
                  placeholder="Enter active status"
                  disabled
                />
              </div>
              <div className="form-group">
                <label htmlFor="priority">Priority</label>
                <input
                  type="text"
                  className="form-control"
                  name="priority"
                  placeholder="Enter priority"
                  disabled
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddFlightInfo}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default State;
