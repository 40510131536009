import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "../components/Login";

const LoginPage = () => {
  return (
    <div>
      <Login />
    </div>
  );
};

export default LoginPage;
