import { createSlice } from "@reduxjs/toolkit";

const ConfirmationSlice = createSlice({
  name: "ConfirmationCode",
  initialState: {
    confirmationCode: null,
  },
  reducers: {
    addConfirmationCode: (state, action) => {
      state.confirmationCode = action.payload;
    },
    removeConfirmationCode: (state) => {
      state.confirmationCode = null;
    },
  },
});

export const { addConfirmationCode, removeConfirmationCode } =
  ConfirmationSlice.actions;

export default ConfirmationSlice.reducer;
