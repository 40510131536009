import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Pagination, Modal, Button } from "react-bootstrap";

const TransitPricing = () => {
  const [transitStepData, settransitStepData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [airlineName, setAirlineName] = useState("");
  const [airlineIataCode, setAirlineIataCode] = useState("");
  const [checkUpdate, setCheckUpdate] = useState(null);
  const [transitStepCode, settransitStepCode] = useState("");
  const [fromAirport, setFromAirport] = useState("");
  const [toAirport, setToAirport] = useState("");
  const [domestic, setDomestic] = useState(); // Set default value for domestic and international
  const [international, setInternational] = useState(); // Set default value for domestic and international
  const [countries, setCountries] = useState([]);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = transitStepData.slice(indexOfFirstRow, indexOfLastRow);
  const [active, setActive] = useState(false);
  const [amount, setAmount] = useState("");
  const [airport, setAirport] = useState([]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setAirlineName("");
    setAirlineIataCode("");
    settransitStepCode("");
    setDomestic(false);
    setInternational(false);
    setActive(false);
    setCheckUpdate(null);

    setShowModal(false);
  };

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}transitPricing`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        settransitStepData(response.data);
        //(response.data.data);
      })
      .catch((error) => {
        //(error);
      });
    axios
      .get(`${process.env.REACT_APP_API_URL}country`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {
        //(error);
      });
  }, [showModal]);

  const handleAddTransitCode = () => {
    console.log(fromAirport);
    console.log(toAirport);
    console.log(amount);
    console.log(active);
    if (!fromAirport || !toAirport || !amount) {
      alert("Please fill all the fields");
      return;
    }

    const formData = {
      from_airport_id: fromAirport,
      to_airport_id: toAirport,
      amount: amount,

      status: active ? 1 : 0,
    };

    if (checkUpdate !== null) {
      //("update");
      axios
        .post(
          `${process.env.REACT_APP_API_URL}transitPricing/${checkUpdate}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          settransitStepData([...transitStepData, response.data.data]);
          setFromAirport("");
          setToAirport("");
          setAmount("");

          settransitStepCode("");

          setActive(false);
          setShowModal(false);
          setCheckUpdate(null);
          //(formData);
        })
        .catch((error) => {
          console.log(error);
          //(error);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}transitPricing`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          settransitStepData([...transitStepData, response.data.data]);
          setFromAirport("");

          setToAirport("");
          setAmount("");

          settransitStepCode("");

          setActive(false);
          setCheckUpdate(null);
          setShowModal(false);
          //(formData);
        })
        .catch((error) => {
          //(error);
        });
    }
  };
  const handleUpdateAirline = (e) => {
    handleShowModal();
    setCheckUpdate(e);
    console.log(e);

    axios
      .get(`${process.env.REACT_APP_API_URL}transitPricing/${e}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        // settransitStepCode(response.data[0].transit_code);
        setFromAirport(response.data[0].from_airport_id);
        setToAirport(response.data[0].to_airport_id);
        setAmount(response.data[0].amount);

        setActive(response.data[0].status);
      })
      .catch((error) => {
        //(error);
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}airport`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setAirport(response.data.data);
      })
      .catch((error) => {
        //(error);
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Transit Pricing</h1>
        <button
          className="btn btn-primary "
          style={{ marginRight: "1rem" }}
          onClick={handleShowModal}
        >
          ADD
        </button>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>SNo.</th>
            <th>From Airport</th>
            <th>To Airport</th>
            <th>Amount(USD)</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((transit, index) => (
            <tr key={index}>
              <td>{indexOfFirstRow + index + 1}</td>
              <td>{transit?.from_airport}</td>
              <td>{transit?.to_airport}</td>
              <td
                onClick={() => handleUpdateAirline(transit?.transit_pricing_id)}
                style={{
                  cursor: "pointer",
                  color: "blue",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {transit?.amount}
              </td>

              <td>{transit?.status ? "Yes" : "No"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination>
        {Array(Math.ceil(transitStepData.length / rowsPerPage))
          .fill()
          .map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
      </Pagination>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Transit Pricing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="addAirlineForm">
            <div className="form-group">
              <label htmlFor="fromAirport">From Airport</label>
              <select
                className="form-control"
                onChange={(e) => setFromAirport(e.target.value)}
                name="fromAirport"
                value={fromAirport}
                required
              >
                <option value="">Select a Airport</option>
                {airport?.map((step) => (
                  <option key={step.airport_iata_code} value={step.airport_id}>
                    {step.airport_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="toAirport">To Airport</label>
              <select
                className="form-control"
                onChange={(e) => setToAirport(e.target.value)}
                name="toAirport"
                value={toAirport}
                required
              >
                <option value="">Select a Airport</option>
                {airport.map((step) => (
                  <option key={step.airport_iata_code} value={step.airport_id}>
                    {step.airport_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="amount">Amount</label>
              <input
                type="number"
                className="form-control"
                onChange={(e) => setAmount(e.target.value)}
                name="amount"
                placeholder="Enter Amount"
                value={amount}
                required
              />
            </div>

            <div className="form-group">
              <div style={{ display: "flex" }}>
                <label
                  htmlFor="active"
                  style={{
                    marginRight: "1rem",
                  }}
                >
                  Status
                </label>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => setActive(e.target.checked)}
                    name="active"
                    checked={active}
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddTransitCode}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TransitPricing;
