import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../assets/MMFLogo_SVG.svg";
const Login = () => {
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/login`,
        {
          email,
          password,
        }
      );
      console.log(response);
      console.log(email, password);
      if (response.status === 200) {
        const data = response.data;
        const token = data.token;
        console.log(data.data.token);

        localStorage.setItem("token", data.data.token);

        setemail("");
        setPassword("");

        window.location.replace("/");
        // navigate("/");
      } else {
        alert("Login failed");
        console.log("Login failed");
      }
    } catch (error) {
      alert("Login failed");
      console.log(error);
    }
  };

  return (
    <div>
      <div className="login-container">
        <div className="login-form">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "80px",
            }}
          >
            <img src={logo} alt="logo" />
          </div>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="email">email</label>
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-primary btn-login">
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
