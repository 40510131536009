import React, { useEffect, useState } from "react";
import FetchDetails from "./FetchDetails";
import data from "../data/FetchData";
import axios from "axios";
import { useSelector } from "react-redux";

import Sidebar from "./Sidebar";
const TravelDetails = () => {
  const Code = useSelector((state) => state.ConfirmationCode.confirmationCode);

  const [confirmationCode, setConfirmationCode] = useState("");

  const [searchResult, setSearchResult] = useState();
  const [searchResponse, setSearchResponse] = useState(false);
  const [api, setApi] = useState(false);

  const handleSearch = async (yash) => {
    if (confirmationCode.length === 0) {
      setSearchResult();
      alert("Please enter confirmation code!");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}travel_plans/${confirmationCode}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // //("Response:", response.data);
      // //("Response:", response.data.travelPlans.length);

      if (
        response.data.customer.length === 0 &&
        response.data.travelPlans.length === 0
      ) {
        setSearchResponse(true);
        setSearchResult();
      } else {
        setSearchResult(response.data);
        setSearchResponse(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setSearchResult();
      setSearchResponse(true);
      // alert(error?.response?.data?.data || "Something went wrong!");
    }
  };
  const check = (yash) => {
    //(yash);
    if (yash == true) {
      handleSearch();
    }
  };
  useEffect(() => {
    if (api == true) {
      handleSearch();
    }
  }, [api]);

  useEffect(() => {
    if (Code != null) {
      setConfirmationCode(Code);
      setApi(true);
    }
  }, [Code]);

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="flex-grow-1">
        <div className="bg-transparent ">
          <div className="container mt-5">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Confirmation Code"
                    aria-label="Search"
                    aria-describedby="button-addon2"
                    value={confirmationCode}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault(); // Prevent the default form submission behavior
                        handleSearch();
                      }
                    }}
                    onChange={(e) => setConfirmationCode(e.target.value)}
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="button-addon2"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          {searchResponse == 1 ? (
            <div className="alert alert-danger" role="alert">
              No data found!
            </div>
          ) : (
            <FetchDetails
              data={{
                searchResult,
                check,
                confirmationCode,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TravelDetails;
