import React from "react";
import TravelDetails from "../components/TravelDetails";
import { Navbar } from "react-bootstrap";
import NavbarComponent from "../components/Navbar";

const Travel = () => {
  return (
    <div>
      <NavbarComponent />
      <TravelDetails />
    </div>
  );
};

export default Travel;
