import React from "react";
import Language from "../components/Language";
import Sidebar from "../components/Sidebar";
import NavbarComponent from "../components/Navbar";

const LanguagePage = () => {
  return (
    <>
      <NavbarComponent />
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1">
          <Language />
        </div>
      </div>
    </>
  );
};

export default LanguagePage;
