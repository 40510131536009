import React from "react";
import Sidebar from "../components/Sidebar";

import NavbarComponent from "../components/Navbar";
import TransitSteps from "../components/TransitSteps";
const TransitStepsPage = () => {
  return (
    <div>
      <NavbarComponent />
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1">
          <TransitSteps />
        </div>
      </div>
    </div>
  );
};

export default TransitStepsPage;
