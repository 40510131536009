import React from "react";
import logo from "../assets/MMFLogo_SVG.svg";

import { useNavigate } from "react-router-dom";

const NavbarComponent = () => {
  const token = !!localStorage.getItem("token");

  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.replace("/login");
  };

  const handleLogin = () => {
    navigate("/login");
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light shadow-sm mb-4">
      <div className="container">
        <a className="navbar-brand" href="#">
          <img src={logo} alt="logo" width="50" height="50" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#basic-navbar-nav"
          aria-controls="basic-navbar-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="basic-navbar-nav">
          <ul className="navbar-nav ms-auto"></ul>
          {token ? (
            <button className="btn btn-primary" onClick={handleLogout}>
              Logout
            </button>
          ) : (
            <button className="btn btn-primary" onClick={handleLogin}>
              Login
            </button>
          )}
          {/* <button className="btn btn-primary">Login</button> */}
        </div>
      </div>
    </nav>
  );
};

export default NavbarComponent;
