import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Pagination, Modal, Button } from "react-bootstrap"; // Import Modal and Button from react-bootstrap

const Airline = () => {
  const [airlineData, setAirlineData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [airlineName, setAirlineName] = useState("");
  const [airlineIataCode, setAirlineIataCode] = useState("");
  const [checkUpdate, setCheckUpdate] = useState(null);
  const [countryName, setCountryName] = useState("");
  const [domestic, setDomestic] = useState(); // Set default value for domestic and international
  const [international, setInternational] = useState(); // Set default value for domestic and international
  const [countries, setCountries] = useState([]);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = airlineData.slice(indexOfFirstRow, indexOfLastRow);
  const [active, setActive] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setAirlineName("");
    setAirlineIataCode("");
    setCountryName("");
    setDomestic(false);
    setInternational(false);
    setActive(false);
    setCheckUpdate(null);

    setShowModal(false);
  };

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    axios

      .get(`${process.env.REACT_APP_API_URL}airline`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })

      .then((response) => {
        setAirlineData(response.data.data);
        //(response.data.data);
      })
      .catch((error) => {
        //(error);
      });
    axios

      .get(`${process.env.REACT_APP_API_URL}country`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })

      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {
        //(error);
      });
  }, [showModal]);

  const handleAddAirline = () => {
    if (!airlineName || !airlineIataCode || !countryName) {
      alert("Please fill all the fields");
      return;
    }

    const formData = {
      airline_name: airlineName,
      airline_iata_code: airlineIataCode,
      country_id: countryName,
      domestic: domestic ? 1 : 0,
      international: international ? 1 : 0,
      active: active ? 1 : 0,
      priority: 99,
    };

    //(formData);
    //(formData);
    //(checkUpdate);
    if (checkUpdate !== null) {
      //("update");
      axios

        .post(
          `${process.env.REACT_APP_API_URL}airline/${checkUpdate}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )

        .then((response) => {
          setAirlineData([...airlineData, response.data.data]);
          setAirlineIataCode("");
          setAirlineName("");
          setCountryName("");
          setDomestic(false);
          setInternational(false);
          setActive(false);
          setShowModal(false);
          setCheckUpdate(null);
          //(formData);
        })
        .catch((error) => {
          //(error);
        });
    } else {
      axios

        .post(`${process.env.REACT_APP_API_URL}createAirline`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })

        .then((response) => {
          setAirlineData([...airlineData, response.data.data]);
          setAirlineIataCode("");
          setAirlineName("");
          setCountryName("");
          setDomestic(false);
          setInternational(false);
          setActive(false);
          setCheckUpdate(null);
          setShowModal(false);
          //(formData);
        })
        .catch((error) => {
          //(error);
        });
    }
  };
  const handleUpdateAirline = (e) => {
    handleShowModal();
    setCheckUpdate(e);
    //(e);
    axios

      .get(`${process.env.REACT_APP_API_URL}airline/${e}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })

      .then((response) => {
        //(response.data.data);
        setAirlineName(response.data.data.airline_name);
        setAirlineIataCode(response.data.data.airline_iata_code);
        setCountryName(response.data.data.country_id);
        setDomestic(response.data.data.domestic);
        setInternational(response.data.data.international);
        setActive(response.data.data.active);
      })
      .catch((error) => {
        //(error);
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Airline</h1>
        <button
          className="btn btn-primary "
          style={{ marginRight: "1rem" }}
          onClick={handleShowModal}
        >
          ADD
        </button>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>SNo.</th>
            <th>Airline Name</th>
            <th>Airline IATA Code</th>
            <th>Country Name</th>
            <th>Domestic</th>
            <th>International</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((airline, index) => (
            <tr key={airline?.airline_id}>
              <td>{indexOfFirstRow + index + 1}</td>
              <td
                onClick={() => handleUpdateAirline(airline?.airline_id)}
                style={{
                  cursor: "pointer",
                  color: "blue",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {airline?.airline_name}
              </td>
              <td>{airline?.airline_iata_code}</td>
              <td>{airline?.country_name}</td>
              <td>{airline?.domestic ? "Yes" : "No"}</td>
              <td>{airline?.international ? "Yes" : "No"}</td>
              <td>{airline?.active ? "Yes" : "No"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination>
        {Array(Math.ceil(airlineData.length / rowsPerPage))
          .fill()
          .map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
      </Pagination>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Airline</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="addAirlineForm">
            <div className="form-group">
              <label htmlFor="airlineName">Airline Name</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setAirlineName(e.target.value)}
                name="airlineName"
                placeholder="Enter airline name"
                value={airlineName}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="airlineIataCode">Airline IATA Code</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setAirlineIataCode(e.target.value)}
                name="airlineIataCode"
                placeholder="Enter airline IATA code"
                value={airlineIataCode}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="countryName">Country Name</label>
              <select
                className="form-control"
                onChange={(e) => setCountryName(e.target.value)}
                name="countryName"
                value={countryName}
                required
              >
                <option value="">Select a country</option>

                {countries?.map((country) => (
                  //(country),
                  <option key={country.country_code} value={country.country_id}>
                    {country.country_name}
                  </option>
                  // <option key={} value={"yashcsbcjh"}>
                  //   {country}
                  // </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <div style={{ display: "flex" }}>
                <label htmlFor="domestic" style={{ marginRight: "1rem" }}>
                  Domestic
                </label>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => setDomestic(e.target.checked)}
                    name="domestic"
                    checked={domestic}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div style={{ display: "flex" }}>
                <label
                  htmlFor="international"
                  style={{
                    marginRight: "1rem",
                  }}
                >
                  International
                </label>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => setInternational(e.target.checked)}
                    name="international"
                    checked={international}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div style={{ display: "flex" }}>
                <label
                  htmlFor="active"
                  style={{
                    marginRight: "1rem",
                  }}
                >
                  Active
                </label>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => setActive(e.target.checked)}
                    name="active"
                    checked={active}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="priority">Priority</label>
              <input
                type="text"
                className="form-control"
                name="priority"
                placeholder="Enter priority"
                disabled
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddAirline}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Airline;

// const [searchTerm, setSearchTerm] = useState("");

//         const handleSearch = () => {
//           // Filter the flight information data based on the search term
//           const filteredData = flightInfoData.filter((flightInfo) =>
//             flightInfo.flight_code.toLowerCase().includes(searchTerm.toLowerCase())
//           );
//           // Update the state with the filtered data
//           setFlightInfoData(filteredData);
//         };

{
  /* <input
type="text"
className="form-control"
placeholder="Search by flight code"
style={{ width: "350px", marginRight: "1rem" }}
value={searchTerm}
onChange={(e) => setSearchTerm(e.target.value)}
/>
<button className="btn btn-primary" onClick={handleSearch}>
Search
</button>
</div> */
}
