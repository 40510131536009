import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Pagination, Modal, Button } from "react-bootstrap"; // Import Modal and Button from react-bootstrap

const FlightInformation = () => {
  const [flightInfoData, setFlightInfoData] = useState([]);
  const [fromAirport, setFromAirports] = useState("");
  const [toAirport, setToAirports] = useState("");
  const [airports, setAirports] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(15);
  const [flightCode, setFlightCode] = useState("");
  const [airlineName, setAirlineName] = useState("");
  const [fromAirports, setFromAirport] = useState("");
  const [toAirports, setToAirport] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [checkUpdate, setCheckUpdate] = useState(null);
  const [airlineOptions, setAirlineOptions] = useState();
  const [active, setActive] = useState(false);
  const [flightCodeError, setFlightCodeError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setFlightCode("");
    setAirlineName("");
    setFromAirports("");
    setToAirports("");
    setActive(false);
    setFlightCodeError("");

    setCheckUpdate(null);

    setShowModal(false);
  };

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}flightinformation`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setFlightInfoData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [showModal]);

  const handleAddFlightInfo = () => {
    //(flightCode, fromAirport, toAirport, airlineName);
    if (!flightCode || !airlineName || !fromAirport || !toAirport) {
      alert("Please fill all the fields");
      return;
    }

    const formData = {
      from_airport_id: fromAirport,
      to_airport_id: toAirport,
      airline_id: airlineName,
      flight_code: flightCode,
      frequency: "M,T,W,Th,F,Sa,St",
      active: active ? 1 : 0,
      priority: "10",
    };

    if (checkUpdate !== null) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}flightInformation/${checkUpdate}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setFlightInfoData([...flightInfoData, response.data.data]);
          setFlightCode("");
          setAirlineName("");
          setFromAirports("");
          setToAirports("");
          setActive(false);

          setShowModal(false);
          setCheckUpdate(null);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}createFlightInformation`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setFlightInfoData([...flightInfoData, response.data.data]);
          setFlightCode("");
          setAirlineName("");
          setFromAirport("");
          setToAirport("");
          setActive(false);
          setArrivalTime("");
          setCheckUpdate(null);
          setShowModal(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleUpdateFlightInfo = (e) => {
    //(e);
    handleShowModal();
    setCheckUpdate(e);
    axios
      .get(`${process.env.REACT_APP_API_URL}flightInformation/${e}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        //(response.data);
        setFlightCode(response.data.data.flight_code);
        setAirlineName(response.data.data.airline_id);
        setFromAirports(response.data.data.from_airport_id);
        setToAirports(response.data.data.to_airport_id);
        setActive(response.data.data.active === 1 ? true : false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}allAirline`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setAirlineOptions(response.data.data);
      })
      .catch((error) => {
        //(error);
      });
  }, [showModal]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}allAirports`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setAirports(response.data.data);
        //("airports");
        //(response.data.data);
      })
      .catch((error) => {
        //(error);
      });
  }, []);
  const handleFlightCodeChange = (e) => {
    const value = e.target.value;
    setFlightCode(value);

    // Validate flight code input with regex pattern
    const regex =
      /^([A-Za-z]{2}|[A-Za-z]{3}|[A-Za-z][0-9]|[0-9][A-Za-z])([0-9]+)$/;
    if (!regex.test(value)) {
      setFlightCodeError("Invalid flight code format");
    } else {
      setFlightCodeError("");
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };
  const filteredData = flightInfoData.filter((flightInfo) =>
    flightInfo.flight_code.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Flight</h1>
        <div style={{ display: "flex" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            style={{ width: "350px", marginRight: "1rem" }}
            onChange={handleSearchChange}
          />
          {/* <button className="btn btn-primary">Search</button> */}
        </div>

        <button
          className="btn btn-primary "
          style={{ marginRight: "1rem" }}
          onClick={handleShowModal}
        >
          ADD
        </button>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>SNo.</th>
            <th>Flight Code</th>
            <th>Airline Name</th>
            <th>From Airport</th>
            <th>To Airport</th>
            <th>Active</th>
          </tr>
        </thead>

        <tbody>
          {currentRows.map((flightInfo, index) => (
            <tr key={flightInfo?.flight_id}>
              <td>{indexOfFirstRow + index + 1}</td>
              <td
                onClick={() => handleUpdateFlightInfo(flightInfo?.flight_id)}
                style={{
                  cursor: "pointer",
                  color: "blue",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {flightInfo?.flight_code}
              </td>
              <td>{flightInfo?.airline_name}</td>
              <td>{flightInfo?.from_airport_name}</td>
              <td>{flightInfo?.to_airport_name}</td>
              <td>{flightInfo?.active === 1 ? "Yes" : "No"}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-center">
        <Pagination>
          {pageNumbers.map((number, index) => {
            if (
              index === 0 ||
              index === pageNumbers.length - 1 ||
              Math.abs(currentPage - number) <= 1
            ) {
              return (
                <Pagination.Item
                  key={number}
                  active={number === currentPage}
                  onClick={() => paginate(number)}
                >
                  {number}
                </Pagination.Item>
              );
            } else if (index === 1 || index === pageNumbers.length - 2) {
              return (
                <Pagination.Ellipsis
                  key={number}
                  onClick={() => paginate(number)}
                />
              );
            } else {
              return null;
            }
          })}
        </Pagination>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Flight Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="addFlightInfoForm">
            <div className="form-group">
              <label htmlFor="flightCode">Flight Code</label>
              <input
                type="text"
                className={`form-control ${
                  flightCodeError ? "is-invalid" : ""
                }`}
                onChange={handleFlightCodeChange}
                name="flightCode"
                placeholder="Enter Flight code eg: UA123"
                value={flightCode}
                required
              />
              {flightCodeError && (
                <div className="invalid-feedback">{flightCodeError}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="airlineName">Airline Name</label>
              <select
                className="form-control"
                onChange={(e) => setAirlineName(e.target.value)}
                name="airlineName"
                value={airlineName}
                required
              >
                <option value="">Select an airline</option>
                {airlineOptions?.map((airline) => (
                  <option key={airline.airline_id} value={airline.airline_id}>
                    {airline.airline_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="fromAirport">From Airport</label>
              <select
                className="form-control"
                onChange={(e) => setFromAirports(e.target.value)}
                name="fromAirport"
                value={fromAirport}
                required
              >
                <option value="">Select an airport</option>
                {airports?.map((airport) => (
                  <option key={airport.airport_id} value={airport.airport_id}>
                    {airport.airport_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="departureTime">To Airport</label>
              <select
                className="form-control"
                onChange={(e) => setToAirports(e.target.value)}
                name="departureTime"
                value={toAirport}
                required
              >
                <option value="">Select an airport</option>
                {airports?.map((airport) => (
                  <option key={airport.airport_id} value={airport.airport_id}>
                    {airport.airport_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <div style={{ display: "flex" }}>
                <label
                  htmlFor="active"
                  style={{
                    marginRight: "1rem",
                  }}
                >
                  Active
                </label>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => setActive(e.target.checked)}
                    name="active"
                    checked={active}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="priority">Priority</label>
              <input
                type="text"
                className="form-control"
                name="priority"
                placeholder="Enter priority"
                disabled
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddFlightInfo}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FlightInformation;
