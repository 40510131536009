import React, { useState, useEffect } from "react";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RiDeleteBinLine } from "react-icons/ri";

const AirportSourceTransitStep = () => {
  const [airportName, setAirportName] = useState([]);
  const [selectedAirport, setSelectedAirport] = useState("");
  const [transitCodeByAirportId, setTransitCodeByAirportId] = useState([]);
  const [transitSteps, setTransitSteps] = useState([]);
  const [show, setShow] = useState(false);
  const [addTransitCode, setAddTransitCode] = useState([]);
  const [save, setSave] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = reorder(
      transitCodeByAirportId,
      result.source.index,
      result.destination.index
    );

    setTransitCodeByAirportId(items);
    setSave(true);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}airport/${selectedCity}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(selectedCity);
        console.log(response.data.data);
        setAirportName(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedCity]);

  const handleAirportChange = async (event) => {
    setSelectedAirport("");
    const selectedAirportCode = event.target.value;

    setSelectedAirport(selectedAirportCode);
    setAddTransitCode([]);

    setShow(true);
    setSave(false);

    try {
      const response1 = await axios.get(
        `${process.env.REACT_APP_API_URL}airportSourceTransitStep/${selectedAirportCode}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const response2 = await axios.get(
        `${process.env.REACT_APP_API_URL}transit_steps`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const transitCodeByAirportIdData = response1.data;
      setTransitCodeByAirportId(transitCodeByAirportIdData);

      const filteredTransitSteps = response2.data.filter((transitStep) => {
        return !transitCodeByAirportIdData.some(
          (item) => item.transit_code === transitStep.transit_code
        );
      });
      setTransitSteps(filteredTransitSteps);
    } catch (error) {
      console.log(error);
    }
  };

  const handleButtonClick = (transitCode) => {
    setSave(true);
    const updatedTransitCodeByAirportId = [
      ...transitCodeByAirportId,
      { transit_code: transitCode },
    ];
    setTransitCodeByAirportId(updatedTransitCodeByAirportId);

    const filteredTransitSteps = transitSteps.filter((transitStep) => {
      return transitStep.transit_code !== transitCode;
    });
    setTransitSteps(filteredTransitSteps);

    const updatedAddTransitCode = [...addTransitCode, transitCode];
    setAddTransitCode(updatedAddTransitCode);
  };

  const handleSubmit = () => {
    const transitCodes = transitCodeByAirportId.map(
      (transit) => transit.transit_code
    );

    axios
      .post(
        `${process.env.REACT_APP_API_URL}createAirportSourceTransitTable`,
        {
          airport_id: selectedAirport,
          transit_code: transitCodes,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        alert("Success!!");
        setSave(false);
      })
      .catch((error) => {
        alert("Something went wrong!!");
      });
  };

  const handleDeleteButtonClick = (e) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}deleteAirportSourceTransitStep`,
        {
          airport_id: selectedAirport,
          transit_code: e,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        const updatedTransitCodeByAirportId = transitCodeByAirportId.filter(
          (item) => item.transit_code !== e
        );

        setTransitCodeByAirportId(updatedTransitCodeByAirportId);

        const updatedTransitSteps = [...transitSteps, { transit_code: e }];
        setTransitSteps(updatedTransitSteps);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchStatesByCountry = (countryId) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}state/${countryId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setStates(response.data.data);
      })
      .catch((error) => {});
  };
  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value;
    setSelectedCountry(selectedCountryId);

    if (selectedCountryId) {
      fetchStatesByCountry(selectedCountryId);
    } else {
      setStates([]);
    }
  };
  const handleStateChange = (event) => {
    const selectedStateId = event.target.value;
    setSelectedState(selectedStateId);

    if (selectedStateId) {
      fetchCitiesByState(selectedStateId);
    } else {
      setCities([]);
      setSelectedCity("");
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}allCountry`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {});
  }, []);

  const fetchCitiesByState = (stateId) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}city/${stateId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setCities(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    setSelectedCity(selectedCityId);
  };

  return (
    <div className="container">
      <div className="row">
        <h2 className="mt-3">Airport Source Transit Step</h2>
        <div className="row">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <select
              className="form-select"
              value={selectedCountry}
              onChange={handleCountryChange}
              style={{ width: "50%" }}
            >
              <option value="">Select a Country</option>
              {countries.map((country) => (
                <option key={country.country_id} value={country.country_id}>
                  {country.country_name}
                </option>
              ))}
            </select>
          </div>

          {selectedCountry && (
            <div className="d-flex justify-content-between align-items-center mb-4">
              <select
                className="form-select"
                value={selectedState}
                onChange={handleStateChange}
                style={{ width: "50%" }}
              >
                <option value="">Select a State</option>
                {states.map((state) => (
                  <option key={state.state_id} value={state.state_id}>
                    {state.state_name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {selectedState && cities.length > 0 && (
            <div className="d-flex justify-content-between align-items-center mb-4">
              <select
                className="form-select"
                value={selectedCity}
                onChange={handleCityChange}
                style={{ width: "50%" }}
              >
                <option value="">Select a City</option>
                {cities.map((city) => (
                  <option key={city.city_id} value={city.city_id}>
                    {city.city_name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {selectedCity && (
            <div className="col-md-6">
              <select className="form-select" onChange={handleAirportChange}>
                <option value="">Select the Airport</option>
                {airportName.map((airport) => (
                  <option
                    key={airport.airport_iata_code}
                    value={airport.airport_id}
                  >
                    {airport.airport_name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>
      {show && (
        <div className="container">
          <div className="row mt-3">
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="col-md-6">
                <h6>Transit Steps not associated with the Airport</h6>
                {transitSteps.map((transitCode, index) => (
                  <div key={index} className="alert alert-primary" role="alert">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {transitCode.transit_code}
                      <button
                        className="btn btn-primary ms-2"
                        onClick={() =>
                          handleButtonClick(transitCode.transit_code)
                        }
                      >
                        ADD
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-6">
                <h6>Transit Steps associated with the Airport</h6>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {transitCodeByAirportId.map((transitCode, index) => (
                        <Draggable
                          key={transitCode.transit_code}
                          draggableId={transitCode.transit_code}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="alert alert-primary"
                              role="alert"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div>{transitCode.transit_code}</div>
                                <button
                                  className="btn btn-danger ms-2"
                                  onClick={() =>
                                    handleDeleteButtonClick(
                                      transitCode.transit_code
                                    )
                                  }
                                >
                                  <RiDeleteBinLine />
                                </button>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                {save && (
                  <div>
                    <button
                      className="btn btn-primary ms-2"
                      onClick={handleSubmit}
                    >
                      Save Changes
                    </button>
                  </div>
                )}
              </div>
            </DragDropContext>
          </div>
        </div>
      )}
    </div>
  );
};

export default AirportSourceTransitStep;
