import React from "react";
import AgeRange from "../components/AgeRange";
import Sidebar from "../components/Sidebar";
import NavbarComponent from "../components/Navbar";
import Phone from "../components/Phone";
import Service from "../components/Service";

const ServicePage = () => {
  return (
    <>
      <NavbarComponent />
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1">
          <Service />
        </div>
      </div>
    </>
  );
};

export default ServicePage;
