import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "../components/Sidebar";
import { Navbar } from "react-bootstrap";
import NavbarComponent from "../components/Navbar";

const apiURL = process.env.REACT_APP_API_URL;

const CountryChart = () => {
  console.log("apiURL:", apiURL);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [chartData, setChartData] = useState([]);
  const [chartType, setChartType] = useState("country");

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "column",
    },
    title: {
      text: "Country Count Chart",
    },
    xAxis: {
      categories: [],
      title: {
        text: "Countries",
      },
    },
    yAxis: {
      title: {
        text: "Count",
      },
    },
    series: [
      {
        name: "Count",
        data: [],
      },
    ],
  });

  const handleSubmit = async () => {
    //("From Date:", fromDate);
    //("To Date:", toDate);
    //("Chart Type:", chartType);

    let apiEndpoint = "";
    switch (chartType) {
      case "country":
        apiEndpoint = "userRegistrations";
        break;
      case "age":
        apiEndpoint = "ageRegistrations";
        break;
      case "gender":
        apiEndpoint = "genderRegistrations";
        break;
      case "traveller":
        apiEndpoint = "travellerRegistrations";
        break;
      default:
        break;
    }

    console.log(
      "apiEndpoint:",
      process.env.REACT_APP_API_URL + "/charts/" + apiEndpoint
    );
    try {
      const response = await axios.post(
        `${apiURL}charts/${apiEndpoint}`,
        {
          fromDate,
          toDate,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      //("Response:", response.data);      {console.log("chartData:", chartOptions)}
      {
        console.log("chartOptions:", Highcharts);
      }
      console.log("Response:", response.data);
      setChartData(response.data.data);
      updateChartOptions(response.data.data);
    } catch (error) {
      console.error("Error:", error);
      console.log(error);
      alert(error?.response?.data?.data || "Something went wrong!");
    }
  };

  const updateChartOptions = (data) => {
    let xAxisTitle = "Categories";
    if (chartType === "country") {
      xAxisTitle = "Country";
    } else if (chartType === "age") {
      xAxisTitle = "AgeRange";
    } else if (chartType === "gender") {
      xAxisTitle = "Gender";
    } else if (chartType === "traveller") {
      xAxisTitle = "Travellers";
    }
    //("xAxisTitle:", data);
    const categories = data?.map((entry) => entry[xAxisTitle]);
    //("categories:", categories);
    const countData = data?.map((entry) => entry.count);
    //("countData:", countData);

    setChartOptions({
      ...chartOptions,
      xAxis: {
        ...chartOptions.xAxis,
        categories,
        title: {
          text: xAxisTitle,
        },
      },
      series: [
        {
          ...chartOptions.series[0],
          data: countData,
        },
      ],
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}charts/userRegistrations`,
          {
            fromDate,
            toDate,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        //("API Response:", response.data);
        console.log("API Response:", response.data);
        setChartData(response.data.data);
        updateChartOptions(response.data.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <NavbarComponent />
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1">
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-6 mx-auto">
                <h2 className="text-center mb-4">Registration Chart</h2>

                <div className="mb-3">
                  <label htmlFor="fromDate" className="form-label me-2">
                    From Date:
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="fromDate"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="toDate" className="form-label me-2">
                    To Date:
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="toDate"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="chartType" className="form-label me-2">
                    Chart Type:
                  </label>
                  <select
                    className="form-select"
                    id="chartType"
                    value={chartType}
                    onChange={(e) => setChartType(e.target.value)}
                  >
                    <option value="country">
                      User Registrations per Country{" "}
                    </option>
                    <option value="age">Travellers by Age</option>
                    <option value="gender">Travellers by Gender</option>
                    <option value="traveller">Travellers per Travel</option>
                  </select>
                </div>

                <button className="btn btn-primary" onClick={handleSubmit}>
                  Submit
                </button>

                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CountryChart;
