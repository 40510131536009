import React, { useEffect, useState } from "react";
import axios from "axios";

const User = () => {
  const [user, setUser] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}user`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setUser(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleChange = async (locked, user_id) => {
    const confirmChange = window.confirm(
      "Are you sure you want to change the locked status of this user?"
    );
    if (confirmChange) {
      locked = locked === 1 ? 0 : 1;

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}user/${user_id}/locked`,
          { locked },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        // Update the user state with the new locked status
        setUser((prevUser) => {
          const updatedUser = prevUser.map((item) => {
            if (item.user_id === user_id) {
              return {
                ...item,
                locked: locked,
              };
            }
            return item;
          });
          return updatedUser;
        });
      } catch (error) {
        console.log(error);
        alert("User locked status change failed");
      }
    } else {
      return;
    }
  };

  return (
    <div>
      <div className="container mt-3">
        <h2>Progress</h2>
        <div className="card bg-light">
          <div className="card-body">
            <div className="container mt-3">
              <div className="card bg-light">
                <div className="card-body overflow-auto">
                  <table className="table table-striped overflow-auto">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Enabled</th>
                        <th>Locked</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item?.customer.first_name}{" "}
                            {item?.customer.last_name}
                          </td>
                          <td>
                            {item?.customer.country_phone_code}{" "}
                            {item?.customer.phone_number}
                          </td>
                          <td>{item?.enabled}</td>
                          <td
                            onClick={() =>
                              handleChange(item?.locked, item?.user_id)
                            }
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              fontWeight: "bold",
                              textDecoration: "underline",
                            }}
                          >
                            {item?.locked}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
